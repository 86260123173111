.history__details__container {
  margin: 10px 10px 10px 10px;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  background-color: #eff3f8;
  padding-left: 20px;
  padding-right: 20px;
}

.history__details__title {
  font-size: 18px;
  font-weight: 400;
  flex-wrap: nowrap;
}
.history__courses__container {
  padding: 10px;
}
.history__courses {
  margin: 5px 5px 5px 5px;
  padding: 10px 10px 10px 10px;
  background: #ffffff;
  box-shadow: 4px 4px 4px 10px #f6f8fa;
  border-radius: 10px;
  margin-bottom: 20px;
  // display: flex;
  // flex-direction: row;
  // justify-content: space-between;
  // position: relative;
}
.history__image {
  width: 120px;
  height: 100px;
  border-radius: 10px;
}
.details__container {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.instructor__details {
  color: #7a899a;
}

.details__outer__container {
  margin: 10px 10px 10px 10px;
  padding: 20px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ratings__container {
  justify-content: center;
}
// .watched__duration {
//   margin-left: -90px;
// }

@media (max-width: 768px) {
  .ratings__container {
    justify-content: start;
    margin-top: 10px;
  }
}

// @media (max-width: 480px) {
//   .watched__duration {
//     margin-left: 0px;
//   }
// }
