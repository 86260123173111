.next__button {
  background-color: #1ea69a;
  margin: 10px 10px 10px 10px;
  width: 150px;
  height: 60px;
  border-radius: 5px;
}
.back__button {
  margin: 10px 10px 10px 10px;
  width: 150px;
  height: 60px;
  border: 2px solid #ff3a66;
  border-radius: 5px;
}
.finish__text {
  color: white;
}
.next__text {
  color: white;
}
.back__text {
  color: #ff3a66;
}
