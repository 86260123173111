.homepagecourses__container {
  // margin: 10px 10px 10px 10px;
  // padding: 10px 10px 10px 10px;
  overflow-y: auto;
}

.courses__card__container {
  margin: 10px 10px 10px 10px;
  padding: 10px 10px 10px 10px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 5px #f6f8fa;
  border-radius: 10px;
}
.courses__title {
  color: #000000 80%;
}
.courses__name {
  font-family: "Poppins";
  color: #575758;
  font-weight: 500;
  font-size: 20px;
}
.courses__description,
.courses__author {
  font-family: "Poppins";
  color: #7f7b7b;
  font-weight: 400;
  font-size: 16px;
}

.courses__duration {
  font-family: "Poppins";
  color: #7f7b7b;
  font-weight: 600;
  font-size: 18px;
}
