:root {
  --div-poppins__semi__bold: "Poppins";
}
* {
  margin: 0px;
}

body {
  width: 100%;
  height: 100%;
}

.overflow--hidden {
  overflow: hidden;
}

a {
  color: #012432;
  text-decoration: none;
}

Link {
  color: #000;
  text-decoration: none;
  border-bottom: none;
}

a,
Link :hover {
  color: #000 70%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins";
  margin: 0%;
}

button {
  cursor: pointer;
}

::-webkit-scrollbar {
  display: none;
  // scrollbar-width: 2px;
}
.overflow-auto {
  overflow: auto !important;
}
.shadow {
  filter: drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.15));
}
.obj-fit {
  object-fit: contain;
}
.flex-one {
  flex: 1;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.space-even {
  display: flex;
  justify-content: space-evenly;
}
.space-around {
  display: flex;
  justify-content: space-around;
}
.text-center {
  text-align: center;
}
.cursor__pointer {
  cursor: pointer;
}
.app__logo {
  object-fit: contain;
  margin: 20px 40px;
  width: 150px;
  height: auto;
}
.sm__icons {
  width: 8% !important;
  float: left !important;
  text-align: center !important;
  filter: drop-shadow(4px 4px 9px rgba(0, 0, 0, 0.25)) !important;
}
.err {
  font-size: small;
  color: red;
}
.asterisk {
  font-size: small;
  color: red;
}
/* justify */
.space {
  justify-content: space-between;
}
.around {
  justify-content: space-around;
}

/* Common Styles */

/* MARGINS */
.mr__0px {
  margin: 0px;
}
.margin__20px {
  margin: 20px;
}
.margin__left__15px {
  margin-left: 15px;
}
.margin__left__30px {
  margin-left: 30px;
}
.margin__top__2px {
  margin-top: 2px;
}
/* PADDINGS */
.padding40__px {
  padding: 40px;
}
.padding__left__10px {
  padding-left: 10px;
}
.padding__left__15px {
  padding-left: 15px;
}
.padding__left__20px {
  padding-left: 20px;
}
.padding__left__40px {
  padding-left: 40px;
}
.padding__left__60px {
  padding-left: 60px;
}
/* WIDTH */

.width30__per {
  width: 30%;
}
.width100__per {
  width: 100%;
}
.width18__rem {
  width: 18rem;
}
.width100__vw {
  width: 100vw;
}
.width250__px {
  width: 250px;
  max-width: 250px;
}
.width350__px {
  width: 350px;
  max-width: 350px;
}
/* HEIGHT */

//Percentage
.height80__per {
  height: 80%;
}

//Pixel
.height__250px {
  height: 250px;
}

.justify-center {
  display: flex;
  justify-content: center;
}

/*  BORDER RADIUS */

.borderradius__10px {
  border-radius: 10px;
}

/* Background colors */
.back__red {
  background-color: red;
}
.back__green {
  background-color: green;
}
.back__blue {
  background-color: blue;
}

/* RADIO BUTTONS */
.radio__button {
  margin: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(196, 196, 196, 0.22);
  box-shadow: 0px 4px 4px #f4f7fa;
}

.form-check-input:checked[type="radio"] {
  background-color: #3fd65de6;
  border: 2px solid #7fc98e;
}
.form-control {
  border-width: 2px;
  border-radius: 5px;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #9bafcd;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(33, 35, 56, 0.05%);
}

/* Media Queries */
@media screen and (max-width: 576px) {
  .app__logo {
    width: 100px;
  }
}
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 992px) {
}
@media screen and (min-width: 993px) {
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(5px);
  }
  60% {
    transform: translateY(3px);
  }
}
