.review__container {
  overflow-y: auto;
  height: 50vh;
}
.reviewscard__container {
  margin: 10px 10px 10px 10px;
  padding: 20px 20px 20px 20px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 5px #f6f8fa;
  border-radius: 10px;
}
.reviewerpost__duration {
  color: #7f7b7b;
  font-size: 14px;
  font-weight: 400;
}
.reviewer__name {
  color: #012432;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
}
.reviewerdetails__container {
  display: flex;
  flex-direction: column;
}
.reviewer__profileImg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}
