.course__detail__container {
  margin-top: 60px;
  padding: 15px 10px 10px 10px;
  background-color: #f4f7fa;
}
.course__title__name {
  color: #000 80;
}
.course__lecture__name {
  color: #012432;
  font-weight: 400;
}
.lectures__container {
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  overflow: auto;
  height: 100%;
}
.greenCard {
  background-color: #f4f7fa;
  border-radius: 5px;
}
.orangeCard {
  background-color: #fef5f3;
  border-radius: 5px;
}
.number__container__orange {
  background-color: #fd7f44;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}
.number__container__green {
  background-color: #7fc98e;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  margin: 10px 10px 10px 10px;
}
.course__detail__review__container {
  padding: 10px 10px 10px 10px;
}
