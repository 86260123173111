.news__container__height {
  margin-top: 40px;
  height: 100vh;
  overflow: auto;
}

.feedscard__container {
  margin: 10px 10px 10px 10px;
  padding: 20px 20px 20px 20px;
  background-color: #fff;
  box-shadow: 0px 4px 4px 5px #f6f8fa;
  border-radius: 10px;
}
.authordetails__container {
  display: flex;
  flex-direction: column;
}
.authors__profileImg {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}
.authors__name {
  color: #012432;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
}
.authorspost__duration {
  color: #7f7b7b;
  font-size: 12px;
  font-weight: 400;
  margin: 2px;
}
.authors__news {
  font-size: 14px;
  font-family: "Poppins";
}
.authors__newsImage {
  // width: 100%;
  // height: 100%;
  border-radius: 5px;
  width: 100%;
}
